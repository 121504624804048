import React from "react";
// import Logo from "../components/Logo";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import config from "../../data/SiteConfig";

const NotFoundPage = () => (
  <div className="flex flex-col min-h-screen overflow-hidden">
    <Helmet title="404: page non trouvée" />
    <div className="h-20 md:h-28"></div>
    {/*  Site header */}
    <Header config={config} />
    {/*  Page content */}
    <main className="flex-grow">
      <section className="relative">
        {/* Illustration behind content */}
        <div
          className="absolute left-1/2 transform -translate-x-1/2 -mb-64 bottom-0 pointer-events-none -z-1"
          aria-hidden="true"
        >
          <svg
            width="1360"
            height="578"
            viewBox="0 0 1360 578"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="illustration-01"
              >
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#EAEAEA" offset="77.402%" />
                <stop stopColor="#DFDFDF" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="url(#illustration-01)" fillRule="evenodd">
              <circle cx="1232" cy="128" r="128" />
              <circle cx="155" cy="443" r="64" />
            </g>
          </svg>
        </div>

        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="max-w-3xl mx-auto text-center">
              {/* 404 content */}
              <h1 className="h1 mb-4">
                Oups ! La page que vous demandez n'existe pas.
              </h1>
              <div className="mt-8">
                <Link
                  to="/"
                  className="btn border-0 border-l-8 border-white text-white bg-red-500 hover:bg-red-400"
                >
                  <span className="material-icons-outlined text-base text-white mr-2">
                    arrow_back_ios
                  </span>
                  Revenir à l'accueil
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    {/*  Site footer */}
    <Footer config={config} />
  </div>
);

export default NotFoundPage;
